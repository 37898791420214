export default {
  primary: {
    backgroundColor: 'transparent',
    color: 'secondary',
    border: 'solid 2px',
    borderColor: 'secondary',
    borderRadius: '0px',
    textTransform: 'uppercase',
    fontFamily: 'body',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: ['0.75rem', '', '0.9rem'],
    padding: '1rem 1.5rem',
    transition: 'all ease-in-out 0.5s',
    border: 'solid 1px white',
    margin: '0rem',
    width: 'fit-content',
    ':hover': {
      backgroundColor: 'primary',
      borderColor: 'white',
    },
  },
  secondary: {
    color: 'light',
    border: 'solid 2px',
    borderColor: 'secondary',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'body',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: ['0.9rem', '', '1rem'],
    padding: '1rem',
    transition: 'all ease-in-out 0.5s',
    ':hover': {
      backgroundColor: 'secondary',
      borderColor: 'white',
    },
  },

  navButton: {
    padding: '0.5rem',
    color: 'white',
    backgroundColor: 'primary',
    borderRadius: '100px',
  },
}
