export default {
  heading: 'Playfair Display, sans-serif',
  subheading: 'Noto Sans Display',
  body: 'Josefin Sans, sans-serif',
  body2: 'Merriweather, sans-serif',
  monospace: 'Menlo, monospace',
  display: '',
  googleFonts: [
    'Playfair Display:400,500,600,700',
    'Noto Sans Display:100,200,300,400,500,600,700,800,900',
    'Merriweather:100,200,300,400,500,600,700,800,900',
    'Josefin Sans:100,200,300,400,500,600,700,800,900',
  ],
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
}
